body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
  background-color: inherit;
  opacity: 0.8;
  border: none;
  cursor: pointer;
}

button:hover {
  opacity: 1;
}

h1.header.ant-select-selection-search-input {
  align-content: center;
  display: flex;
  justify-content: center;
  color: #757575;
}

.prodocthunt {
  width: 250px;
  height: 54px;
}
.producthunta {
  display: flex;
  justify-content: center;
  color: #757575;
  margin-top: 7px;
  margin-bottom: 7px;
}
